<template>
  <main>
    <content-page-header>
      <common-page-title title="パスワードリセット" />
      <div class="page-header-explanation">
        <p class="page-header-explanation-text">
          下記入力フォームにジッセン!DXにご登録いただいたメールアドレスをご入力ください。<br />
          ご申請いただきますと、パスワードリセットのお手続きに関するメールが、ご登録のメールアドレス宛に送信されます。<br />
          メールを受信されましたら、本文に記載あるURLにアクセスいただき、パスワードリセットのお手続きをお願いいたします。
        </p>
      </div>
    </content-page-header>
    <div class="content-body">
      <panel-body>
        <section>
          <common-block-title title="アカウント情報入力" sub-title="" />
          <form id="editUserForm" class="edit-form">
            <label class="form-function">
              <div class="form-input-label">
                メールアドレス<sup class="type-required">*</sup>
              </div>
              <div class="w-full">
                <input
                  type="email"
                  placeholder="メールアドレス"
                  v-model="state.email"
                />
                <div
                  class="error-message"
                  v-for="(error, index) in state.errors"
                  :key="index"
                >
                  <icon name="error" class="icon" />{{ error }}
                </div>
              </div>
            </label>
          </form>
        </section>

        <div class="submit-area">
          <button
            class="submit-area-button submit-area-button--cancel"
            @click="$router.back()"
          >
            Cancel
          </button>
          <button
            class="submit-area-button submit-area-button--save"
            :disabled="state.email == ''"
            @click="submit"
          >
            Send
          </button>
        </div>
      </panel-body>
    </div>
  </main>
  <mydialog
    :isShowAlert="state.showAlert"
    title="メールを送信いたしました"
    subTitle=""
    message="メールが受信されない場合は、迷惑メールのフィルタ設定をご確認いただくか、ご入力いただいたメールアドレスをご確認の上、再度お手続きください。"
    messageColor="black"
    submit="Topページへ戻る"
    @cancelClick="state.showAlert = false"
    @submitClick="$router.push({ path: '/' })"
    @closeDialog="state.showAlert = false"
  />
</template>

<script>
import { defineComponent, reactive, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import http from "@/utils/http";
import contentPageHeader from "@/components/contentPageHeader.vue";
import CommonPageTitle from "@/components/CommonTitle/CommonPageTitle.vue";
import CommonBlockTitle from "@/components/CommonTitle/CommonBlockTitle.vue";
import PanelBody from "@/components/CommonPanelBody/PanelBody.vue";
import mydialog from "@/components/CommonDialog/CommonDialog.vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "resetPasswordSend",

  components: {
    contentPageHeader,
    CommonPageTitle,
    PanelBody,
    CommonBlockTitle,
    mydialog,
  },
  props: [],
  setup() {
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      email: "",
      showAlert: false,
      errors: [],
    });
    const isLogin = computed(() => store.state.user.isLogin);

    window.scrollTo(0, 0);

    // メール送信ボタンクリック
    const submit = () => {
      http
        .post("/api/passwords/reset/send", {
          email: state.email,
        })
        .then((res) => {
          console.log(res.data);
          state.showAlert = true;
          setTimeout(() => {
            document.body.style.overflow = "";
            router.push("/");
          }, 5000);
        })
        .catch((error) => {
          if (error.config && error.response) {
            if (error.response.data.errors) {
              console.log("エラー:", error.response.data.errors);
              state.errors = error.response.data.errors.email;
            } else if (error.response.data.message) {
              console.log("エラー:", error.response.data.message);
              state.errors = [error.response.data.message];
            }
          }
        });
    };

    onMounted(async () => {
      if (isLogin.value) {
        router.push("/");
        return;
      }
    });
    watch(
      () => store.state.user.isLogin,
      async () => {
        if (isLogin.value) {
          router.push("/");
          return;
        }
      }
    );

    return {
      store,
      state,
      submit,
    };
  },
});
</script>

<style lang="scss" scoped>
::v-deep(.content-header) {
  height: auto;
  padding: 100px 100px 200px;
  background-size: cover;
  @include mq(sm) {
    padding: 100px 20px 200px;
  }
}
.content-body {
  position: relative;
  margin-top: -100px;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 50px;
  z-index: 1;

  @include mq(sm) {
    margin-right: 20px;
    margin-left: 20px;
  }
}

.edit-form {
  padding: 65px 0;
  border-bottom: 0.5px solid #0f0f11;
}

</style>